export const practiceOptions = {
  Additions: 'practice.addition',
  Subtractions: 'practice.subtraction',
  Multiplications: 'practice.multiplication',
  Divisions: 'practice.division',
  Fractions: 'practice.fraction',
  // Percentages: 'Percentages',
  // Mixed: 'Mixed'
};

export const QuestionCountOptions = {
  5: 'count.five',
  10: 'count.ten',
  15: 'count.fifteen',
  20: 'count.twenty',
  30: 'count.thirty',
  40: 'count.fourty',
  50: 'count.fifty',
  60: 'count.sixty',
  70: 'count.seventy',
  80: 'count.eighty',
  90: 'count.ninty',
  100: 'count.hundered',
};

// export const TimePerQuestionOptions = {
//   0: 'No time',
//   1: '1 second',
//   2: '2 seconds',
//   3: '3 seconds',
//   4: '4 seconds',
//   5: '5 seconds',
//   7: '7 seconds',
//   10: '10 seconds',
//   12: '12 seconds',
//   15: '15 seconds',
// };

// Time per question in seconds. 0 means no time limit.
export const TimePerQuestionOptions = [0, 1, 3, 5, 10, 15, 20];

// export const TimeAllowedOptions = {
//   1: '1 minute',
//   2: '2 minutes',
//   3: '3 minutes',
//   4: '4 minutes',
//   5: '5 minutes',
//   6: '6 minutes',
// };

// time allowed in minutes.
export const TimeAllowedOptions = [1, 2, 3, 4, 5, 7, 10, 15, 20];
export const UpToTimesOptions = [3, 5, 10, 12, 15, 20];
