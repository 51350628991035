import { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid2';
import { useSelector } from 'react-redux';
import { timeUpdated } from '../../redux/features/allowed-time-slice';
import { useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';

import styles from './AllowedTime.module.scss';
import SelectComponent from '../../shared/components/SelectComponent';
import { TimeAllowedOptions } from '../../shared/constants';
import { MenuItem } from '@mui/material';

import { useTranslation } from 'react-i18next';

const useAllowedTime = () => {
  const dispatch = useAppDispatch();
  const allowedTime = useSelector((state: RootState) => state.allowedTime);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(timeUpdated(event.target.value as string));
  };

  return { allowedTime, handleChange };
};

const AllowedTime = () => {
  const { t } = useTranslation();
  const selectOptions = TimeAllowedOptions.map((m) => (
    <MenuItem key={m} value={m}>
      {t('allowed-time.minute', { count: m })}
    </MenuItem>
  ));

  const { allowedTime, handleChange } = useAllowedTime();
  return (
    <Grid container>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <SelectComponent
          selectOptions={selectOptions}
          selectedValue={allowedTime}
          handleChange={handleChange}
          selectLabel={t('allowed-time.select-1.label-text')}
          labelId="allowedTimeSelectLabel"
          selectId="allowedTimeSelectId"
        />
      </Grid>
    </Grid>
  );
};

export default AllowedTime;
