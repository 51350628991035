import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import HeaderNavBar from './components/HeaderNavbar';
import './App.css';
import { Grid2 as Grid } from '@mui/material';
import Help from './components/Help';
import AboutPage from './components/About';
import LearnTables from './components/LearnTables';
import Practice from './components/Practice';
import variables from './_variables.module.scss';
// see the solution here https://stackoverflow.com/questions/70149171/webpack-scss-cannot-resolve-background-image-url
import classroomImage from './images/classroom.jpg';
import md5Image from './images/md5.jpg';
import SelectionPage from './components/SelectionPage/SelectionPage';
import 'react-toastify/dist/ReactToastify.min.css';
import NotFound from './components/NotFound';

function App() {
  useEffect(() => {
    document.title = 'tifle - Learn with us';
  }, []);
  return (
    <>
      <HeaderNavBar headerText="Tifle" />
      <ToastContainer />
      <Grid
        container
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(${classroomImage})`,
          color: variables.selectColor,
          border: '20px solid transparent',
          borderImage: `url(${md5Image}) 120 round`,
        }}
      >
        <Routes>
          <Route path="/" element={<Help />} />
          {/* <Route path="/" element={<HomePage></HomePage>} /> */}
          <Route path="/help" element={<Help />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/practice" element={<Practice />} />
          <Route path="/learn-tables" element={<LearnTables />} />
          <Route path="/quiz-options" element={<SelectionPage />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Grid>
    </>
  );
}

export default App;
