import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import { QuestionCountOptions } from '../../shared/constants';
import { useNumberOfQuestionsComponent } from './useNumberOfQuestionsComponent';

import styles from './NumberOfQuestionsComponent.module.scss';
import SelectComponent from '../../shared/components/SelectComponent';

import { useTranslation } from 'react-i18next';
const NumberOfQuestionsComponent = () => {
  const { t } = useTranslation();

  const { handleChange, numberOfQuestions } = useNumberOfQuestionsComponent();
  const selectOptions = Object.keys(QuestionCountOptions).map((m) => (
    <MenuItem key={m} value={m}>
      {t(QuestionCountOptions[Number.parseInt(m, 10) as keyof typeof QuestionCountOptions])}
    </MenuItem>
  ));

  return (
    <Grid container>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <SelectComponent
          labelId="no-of-questions-select-label"
          selectId="no-of-questions-select"
          selectOptions={selectOptions}
          selectedValue={numberOfQuestions}
          handleChange={handleChange}
          selectLabel={t('number-of-question-selector.select-1.label')}
        />
      </Grid>
    </Grid>
  );
};

export default NumberOfQuestionsComponent;
