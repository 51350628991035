// src/i18n/LocaleSwitcher.tsx

import { useTranslation } from 'react-i18next';
import { supportedLngs } from '../i18n';
import SelectComponent from '../shared/components/SelectComponent';
import { MenuItem } from '@mui/material';

export default function LocaleSwitcher() {
  const { i18n, t } = useTranslation();

  const selectOptions = Object.entries(supportedLngs).map(([code, name]) => (
    <MenuItem value={code} key={code}>
      {name}
    </MenuItem>
  ));

  return (
    <>
      <SelectComponent
        variant="filled"
        selectOptions={selectOptions}
        selectedValue={i18n.resolvedLanguage as string}
        handleChange={(e) => i18n.changeLanguage(e.target.value)}
        selectLabel={t('language-switcher-select-label')}
        minWidth={0}
        labelId="language-switcher-select-label-id"
        selectId="language-switcher-select-id"
      />
    </>
  );
}
