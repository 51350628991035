import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IAnswer, IQuestion, IPracticePageUIProps } from './PracticePageUI';
import { practiceOptions } from '../shared/constants';
import { startPracticeAgain } from '../redux/features/time-left-slice';
import useRemainingTime from './useRemainingTime';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';

import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { TFunction } from 'i18next';

function generateOperationChar(
  t: TFunction<'translation', undefined>,
  operation: string,
  firstNumber: number,
  secondNumber: number,
) {
  switch (operation) {
    case practiceOptions.Additions:
      return {
        operationChar: t('practice-options.addition.operation-character'),
        correctAnswer: firstNumber + secondNumber,
      };
    case practiceOptions.Subtractions:
      return {
        operationChar: t('practice-options.subtraction.operation-character'),
        correctAnswer: firstNumber - secondNumber,
      };
    case practiceOptions.Multiplications:
      return {
        operationChar: t('practice-options.multiplication.operation-character'),
        correctAnswer: firstNumber * secondNumber,
      };
    case practiceOptions.Divisions:
      return { operationChar: t('practice-options.division.operation-character'), correctAnswer: firstNumber };
    case practiceOptions.Fractions:
      return {
        operationChar: t('practice-options.fraction.operation-character'),
        correctAnswer: firstNumber % secondNumber,
      };
    default:
      return {
        operationChar: t('practice-options.addition.operation-character'),
        correctAnswer: firstNumber + secondNumber,
      };
  }
}

const usePractice = (): IPracticePageUIProps => {
  const { t } = useTranslation();
  const resolvedLocale = i18n.resolvedLanguage;

  const selectedNumbers = useAppSelector((state: RootState) => state.selectedNumbers);
  const whatToPractice = useAppSelector((state: RootState) => state.whatToPractice);
  const timeLeft = useAppSelector((state: RootState) => state.timeLeft);
  const numberOfQuestions = useAppSelector((state: RootState) => state.numberOfQuestions);
  const allowedTime = useAppSelector((state: RootState) => state.allowedTime);
  const timePerQuestion = useAppSelector((state) => state.allowedTimePerQuestion);
  const yourName = useAppSelector((state) => state.yourName);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [storedAnswers, setStoredAnswers] = useState<IAnswer[]>([]);
  const [answer, setAnswer] = useState('');
  const usedTime = storedAnswers.map((m) => m.timeUsed).reduce((a, b) => a + b, 0);
  const answerInput = React.createRef<HTMLInputElement>();

  const yourNameContext = !!yourName ? 'name' : 'noname';

  const enabled = numberOfQuestions > storedAnswers.length;
  useRemainingTime({ enabled });
  const toTimeString = (time: number) => {
    const minutes = Math.floor(time / 60);
    const minutesStr = t('numbers', { value: minutes });
    const secondsStr = t('numbers', { value: time - minutes * 60 });
    // const seconds = time - minutes * 60;
    const timeString = `${`${minutesStr}`}:${`${secondsStr}`}`;
    return timeString;
  };

  const toTimeTextString = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const mins = t('allowed-time.minute', { count: minutes });
    const secs = t('time.second', { count: seconds });
    const timeStr = `${mins} and ${secs}`;

    return timeStr;
  };

  const isCorrect = (answerGiven: string, correctAnswer: number) => Number.parseInt(answerGiven, 10) === correctAnswer;
  const wrongAnswers = storedAnswers.filter((a) => !isCorrect(a.answer, a.correctAnswer)).length;

  const remainingTime = toTimeTextString(timeLeft);
  const numOfQuestionsLeft = numberOfQuestions - storedAnswers.length;
  const quizDone = timeLeft <= 0 || storedAnswers.length >= numberOfQuestions;

  const timePerQuestionEnabled = !!timePerQuestion && Number.parseInt(timePerQuestion, 10) > 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val && !isNaN(parseInt(val, 10)) && !isNaN(+val)) {
      // its a number
      setAnswer((+val).toLocaleString(resolvedLocale));
    } else {
      // its not a number
      setAnswer(e.target.value);
    }
  };
  const [active, setActive] = useState(false);
  const [timePerQuestionLeft, setTimePerQuestionLeft] = useState(!!timePerQuestion ? parseInt(timePerQuestion, 10) : 0);
  const [questionState, setQuestionState] = useState<IQuestion>({
    firstNumber: 0,
    secondNumber: 0,
    operationChar: '+',
    correctAnswer: 0,
    timeUsed: 0,
  });

  const { firstNumber, secondNumber, operationChar } = questionState;

  const generateNumbers = () => {
    const firstNumIndex = Math.floor(Math.random() * selectedNumbers.length);
    const secondNumIndex = Math.floor(Math.random() * selectedNumbers.length);
    let firstNum = selectedNumbers[firstNumIndex];
    let secondNum = selectedNumbers[secondNumIndex];
    if (firstNum < secondNum) {
      const temp = firstNum;
      firstNum = secondNum;
      secondNum = temp;
    }
    const operation = whatToPractice[Math.floor(Math.random() * whatToPractice.length)];

    const { operationChar: operatorChar, correctAnswer } = generateOperationChar(t, operation, firstNum, secondNum);

    firstNum = operation === practiceOptions.Divisions ? firstNum * secondNum : firstNum;

    setQuestionState({
      firstNumber: firstNum,
      secondNumber: secondNum,
      operationChar: operatorChar,
      correctAnswer,
      timeUsed: 0,
    });
  };

  const handleAnswer = (answerToStore: string) => {
    storeAnswer(answerToStore);
    generateNumbers();
    setAnswer('');
    answerInput?.current?.focus();
    setTimePerQuestionLeft((_) => parseInt(timePerQuestion));
  };

  const questionTimeLeft = timePerQuestionLeft;

  useEffect(() => {
    if (selectedNumbers.length <= 0 || whatToPractice.length <= 0) {
      toast.info('Please select Numbers, and what to practice and start the quiz');
      navigate('/quiz-options');
    } else {
      if (timePerQuestionEnabled) {
      }
      generateNumbers();
      document.title = 'tifle - Practice maths';
      answerInput?.current?.focus();
      dispatch(startPracticeAgain(allowedTime));
      setActive(true);
      setTimePerQuestionLeft(parseInt(timePerQuestion, 10));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (active && timePerQuestionEnabled && timeLeft < parseInt(allowedTime, 10) * 60) {
      const newVal = timePerQuestionLeft - 1;
      if (newVal <= 0) {
        handleAnswer(answer);
      } else {
        setTimePerQuestionLeft((_) => newVal);
      }
    }
  }, [active, timePerQuestionEnabled, timeLeft]);

  const storeAnswer = (answerToStore: string) => {
    const timeUsed =
      Number.parseInt(allowedTime, 10) * 60 -
      timeLeft -
      storedAnswers.map((m) => m.timeUsed).reduce((a, b) => a + b, 0);
    const state = { ...questionState, answer: answerToStore, timeUsed };
    setStoredAnswers([...storedAnswers, state]);
  };

  // Add capabilities for timer per questions
  //   along with the totoal time.

  const handleStartAgainClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setStoredAnswers([]);
    dispatch(startPracticeAgain(allowedTime));
  };

  return {
    firstNumber,
    secondNumber,
    onAnswered: handleAnswer,
    operationChar,
    previousAnswers: storedAnswers,
    timeLeft,
    numberOfQuestions,
    handleStartAgainClick,
    toTimeString,
    toTimeTextString,
    allowedTime,
    enabled,
    remainingTime,
    numOfQuestionsLeft,
    quizDone,
    isCorrect,
    handleChange,
    answer,
    usedTime,
    wrongAnswers,
    answerInput,
    questionTimeLeft,
    timePerQuestionEnabled,
    yourName,
    yourNameContext,
  };
};

export default usePractice;
