import { useEffect, useState } from 'react';
import { Grid2 as Grid, Typography, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import styles from './learn-table.module.scss';
import SelectComponent from '../shared/components/SelectComponent';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import { UpToTimesOptions } from '../shared/constants';
import { useAppSelector } from '../app/hooks';

const LearnTables = () => {
  const { t } = useTranslation();
  const resolvedLocale = i18n.resolvedLanguage;
  const yourName = useAppSelector((state) => state.yourName);

  const yourContext = !!yourName ? 'name' : 'noname';
  useEffect(() => {
    document.title = t('learntable-page.title');
  }, []);

  const selectOptions = [...Array(20).keys()].map((m) => (
    <MenuItem key={m + 1} value={m + 1}>
      {(m + 1).toLocaleString(resolvedLocale)}
    </MenuItem>
  ));

  const upToTimesSelectOptions = UpToTimesOptions.map((m) => (
    <MenuItem key={m} value={m}>
      {m.toLocaleString(resolvedLocale)}
    </MenuItem>
  ));

  const [selectedTable, setSelectedTable] = useState<string>('5');
  const [selectedUpToTimes, setSelectedUpToTimes] = useState<string>('10');

  const upToTimes = parseInt(selectedUpToTimes, 10);
  const handleTableChange = (e: SelectChangeEvent<unknown>) => {
    const selection = e.target.value;
    setSelectedTable(selection as string);
  };

  const handleUpToTimesChange = (e: SelectChangeEvent<unknown>) => {
    const selection = e.target.value;
    setSelectedUpToTimes(selection as string);
  };

  return (
    <>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ marginTop: 2 }}>
        <Typography variant="h3" style={{ fontSize: 16 }} className={styles.style} align="center">
          <p>
            {t('learntable-page.p1.text', {
              context: yourContext,
              yourName,
            })}
          </p>
        </Typography>
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ marginTop: 2 }}>
        <SelectComponent
          className={styles.style}
          selectOptions={selectOptions}
          selectedValue={selectedTable}
          handleChange={handleTableChange}
          selectLabel={t('learntable-page.select-table-label')}
          minWidth={150}
          labelId="selectTableSelectLabel"
          selectId="selectTableSelectId"
        />
      </Grid>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12} sx={{ marginTop: 2 }}>
        <SelectComponent
          className={styles.style}
          selectOptions={upToTimesSelectOptions}
          selectedValue={selectedUpToTimes}
          handleChange={handleUpToTimesChange}
          selectLabel={t('learntable-page.upto-times-select-label')}
          minWidth={150}
          labelId="selectTableSelectLabel"
          selectId="selectTableSelectId"
        />
      </Grid>

      <Grid container sx={{ margin: 4 }}>
        {selectedTable &&
          [...Array(upToTimes).keys()].map((m) => (
            <Grid display="flex" className={styles.style} justifyContent="center" alignItems="center" size={12} key={m}>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="right">{Number.parseInt(selectedTable, 10).toLocaleString(resolvedLocale)}</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="center">X</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="left">{(m + 1).toLocaleString(resolvedLocale)}</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="left">=</Box>
              </Typography>
              <Typography variant="h5" sx={{ minWidth: 50 }}>
                <Box textAlign="left" className={styles.color}>
                  {(Number.parseInt(selectedTable, 10) * (m + 1)).toLocaleString(resolvedLocale)}
                </Box>
              </Typography>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default LearnTables;
