import { Box, Button, Grid2 as Grid, Link, Typography } from '@mui/material';
import styles from './help.module.scss';
import kidsChildrenImage from '../images/kids-children.jpg';
import kidsWearingImage from '../images/kids-wearing.jpg';
import patternImage from '../images/Pattern.jpg';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import i18n from '../i18n';

const Help = () => {
  const localeResolved = i18n.resolvedLanguage;
  const { t } = useTranslation();

  return (
    <>
      <Grid />
      <Grid size={{ xs: 12, sm: 6 }} sx={{ justify: 'center' }}>
        <Box sx={{ color: 'secondary.main' }} className={styles.box}>
          <img alt="complex" style={{ margin: 16 }} src={kidsChildrenImage} />
          <br />
          <Typography variant="h3" style={{ fontSize: 22, textTransform: 'none' }} className={styles.typography}>
            {t('help-page.section-1.heading')}
          </Typography>
          <Typography
            variant="subtitle1"
            align="left"
            style={{ fontSize: 18, margin: 8 }}
            className={styles.typography}
          >
            <ol>
              <Trans i18nKey="help-page.section-1.list1">
                <li>
                  Click the{' '}
                  <Link sx={{ color: 'white', textDecoration: 'underline' }} component={RouterLink} to="/quiz-options">
                    quiz options
                  </Link>{' '}
                  link.
                </li>
              </Trans>
              <li>{t('help-page.section-1.list2')}</li>
              <li>{t('help-page.section-1.list3')}</li>
              <li>{t('help-page.section-1.list4')}</li>
              <li>{t('help-page.section-1.list5')}</li>
              <li>{t('help-page.section-1.list6')}</li>
              <li>{t('help-page.section-1.list7')}</li>
              <li>{t('help-page.section-1.list8')}</li>
            </ol>
          </Typography>
          <Button
            sx={{ marginBottom: 2 }}
            component={RouterLink}
            to="/quiz-options"
            color="secondary"
            variant="contained"
            className={styles.typography}
          >
            {t('help-page.section-1.buttonText')}
          </Button>
        </Box>
      </Grid>
      <Grid size={{ md: 1 }} />
      <Grid size={{ xs: 12, sm: 6, md: 5, lg: 5, xl: 5 }} sx={{ justify: 'center' }}>
        <Box className={styles.box}>
          <img alt="complex" src={kidsWearingImage} style={{ margin: 16 }} />
          <Typography variant="h3" style={{ fontSize: 22, textTransform: 'none' }} className={styles.typography}>
            {t('help-page.section-2.heading')}
          </Typography>
          <Typography
            variant="subtitle1"
            align="justify"
            style={{ fontSize: 18, margin: 16 }}
            className={styles.typography}
          >
            <p>{t('help-page.section-2.para-1')}</p>
            <p>{t('help-page.section-2.para-2')}</p>
            <p>{t('help-page.section-2.para-3')}</p>
            <p>{t('help-page.section-2.para-4')}</p>
          </Typography>
          <Button
            component={RouterLink}
            sx={{ marginBottom: 2 }}
            to="/learn-tables"
            variant="contained"
            color="secondary"
            className={styles.typography}
          >
            {t('help-page.section-2.buttonText')}
          </Button>
        </Box>

        <Box className={styles.box}>
          <img alt="complex" src={patternImage} style={{ margin: 16 }} />
          <Typography variant="h3" style={{ fontSize: 22, textTransform: 'none' }} className={styles.typography}>
            {t('help-page.section-3.heading')}
          </Typography>
          <Typography
            variant="subtitle1"
            align="justify"
            style={{ fontSize: 18, margin: 16 }}
            className={styles.typography}
          >
            {/* <p>{t('help-page.section-3.para-1', { currentMonth: currentMonth })}</p> */}
            <p>
              {t('help-page.section-3.para-1', {
                currentMonth: new Date().toLocaleString(localeResolved, { month: 'long' }),
              })}
            </p>
          </Typography>
          <Button
            component={RouterLink}
            sx={{ marginBottom: 2 }}
            to="/learn-tables"
            variant="contained"
            className={styles.typography}
            color="secondary"
          >
            {t('help-page.section-3.buttonText')}
          </Button>
        </Box>
      </Grid>
      <Grid size={{ md: 1 }} />
    </>
  );
};
export default Help;
