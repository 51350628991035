import { Grid2 as Grid, Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';

const buttonClasses = {
  root: {
    marginTop: '40px',
  },
};

const StartPracticeButton = () => {
  const navigate = useNavigate();
  const selectedPractices = useSelector((state: RootState) => state.whatToPractice);
  const selectedNumbers = useSelector((state: RootState) => state.selectedNumbers);
  const selectionNotMade = selectedPractices.length === 0 || selectedNumbers.length === 0;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate('/practice');
  };

  return (
    <Grid container>
      <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
        <Button
          sx={{
            ...buttonClasses,
            '&.Mui-disabled': {
              background: '#525252',
              color: '#c0c0c0',
            },
            width: 200,
            margin: 1,
          }}
          variant="contained"
          size="large"
          color="secondary"
          disabled={selectionNotMade}
          onClick={handleClick}
        >
          Start quiz
        </Button>
      </Grid>
    </Grid>
  );
};

export default StartPracticeButton;
