import { FormGroup, FormControlLabel, CardContent, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid2';
import useNumberSelector from './useNumberSelector';
import variables from '../../_variables.module.scss';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const NumbersSelectorUI = () => {
  const { t } = useTranslation();
  const resolvedLocale = i18n.resolvedLanguage;

  const { handleChange, selectedNumbers } = useNumberSelector();
  const numbersToSelect: JSX.Element[] = [];
  for (let index = 1; index <= 20; index += 1) {
    numbersToSelect.push(
      <Grid display="flex" justifyContent="center" alignItems="center" size={{ xs: 6, md: 3 }} key={index}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedNumbers.find((m) => m === index) === index}
              onChange={handleChange(index)}
              value={`${index}`}
              style={{
                color: variables.baseColor,
              }}
            />
          }
          label={`${t('number-text')} ${index.toLocaleString(resolvedLocale)}`}
        />
      </Grid>,
    );
  }

  return (
    <CardContent>
      <Grid
        container
        style={{
          backgroundColor: 'rgba(0,0,0, 0.4)',
        }}
        spacing={2}
      >
        <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
          <Typography variant="h5" align="center">
            {t('number-selctor.select-1.label-text')}
          </Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center" size={12}>
          <FormGroup row>{numbersToSelect}</FormGroup>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default NumbersSelectorUI;
